.gallery {
  margin-top: 50px;
  margin-bottom: 50px;

  figcaption {
    font-size: 14px;
    color: #666;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    text-align: center;
  }

  figure {
    margin-bottom: 30px;
    text-align: center;
    align-self: center;    

    &.col-12 {
      img {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      img {
        width: 100%;
      }
    }
    
    a img {
      border: 1px solid #bfcad2;
    }
  }
}