.filter {
  background: #e5e9ed;
  padding: 35px 30px 10px;
  margin-bottom: 50px;
}

.filter__wrapper {
  padding: 0 10px;
  margin-bottom: 25px;
}

.filter__select {
  width: 100%;
  padding: 0 15px;
  height: 45px;
  font-size: 16px;
}