.footer {
  border-top: 5px solid $color--main;
  padding-top: 50px;
  font-size: 20px;
  line-height: 36px;
}

.footer__left {
  padding-right: 45px;
}

.footer p {
  margin-bottom: 45px;
}

.footer__copyrights {
  background: $color--main;
  color: #fff;
  padding: 15px 0;
  font-size: 14px;
  margin-top: 50px;
}

.footer__map {
  width: 100%;
  height: 415px;
}

.footer__links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 14px;

  a {
    color: #000;
  }
}

.footer__links__single {
  line-height: 1.15;
  margin-bottom: 10px;
}

.footer__socials {
  font-size: 0;
}

.footer__socials__single {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #bfcad2;
  transition: all .2s;
  margin-right: 10px;

  &:hover {
    border-color: $color--main;
  }
}

.footer__socials__single__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.foot-slogan {
  max-width: 840px;
  margin: 90px auto 80px;
  font-size: 14px;
  line-height: 26px;
  padding-left: 20px;
  padding-right: 20px;

  h3 {
    margin-bottom: 30px;
  }
}