.full-news-single {
  padding-bottom: 20px;
  border-bottom: 1px solid #bfcad2;
  margin-bottom: 40px;
}

.full-news-single__title {
  border-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 10px;
  margin-bottom: 0;

  a {
    color: #000;
  }
}

.full-news-single__date {
  color: #a3a3a3;
  font-size: 14px;
  margin-bottom: 15px;
}

.full-news-single__content {
  &::after {
    content: '';
    display: block;
    clear: both;
  }

  img {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}