$font-stack: -apple-system, BlinkMacSystemFont, Merriweather, "Open Sans", "Helvetica Neue", sans-serif;

@import "normalize";
@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "layout";
@import "navigation";
@import "breadcrumbs";
@import "team";
@import "right-areas";
@import "awards";
@import "news";
@import "filter";
@import "gallery";
// @import "~@glidejs/glide/dist/css/glide.core.min.css";
// @import "~@glidejs/glide/dist/css/glide.theme.min.css";
@import "~flickity/css/flickity.css";
@import "~flickity-fade/flickity-fade.css";
@import "~photoswipe/src/css/main";
// @import "~photoswipe/src/css/default-skin/default-skin";
@import "slider";
@import "footer";
@import "pro-bono";
@import "coronavirus";

body {
  font-family: $font-stack;
}

.container-fluid {
  max-width: 1280px;
}

.header--bordered {
  border-bottom: 1px solid $color--main;
}

.header__logo {
  margin: 24px 0;
  height: 75px;
}

.header__language {
  border: 1px solid #bfbfbf;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }

  img {
    margin-top: -2px;
    margin-right: 5px;
  }
}

img,
svg,
iframe {
  max-width: 100%;
}

.pagination__numbers {
  background: #00487d;
  text-align:center;
  padding: 0;
}

.pagination__numbers__selected {
  background: #002c4d;
}

.pagination__number__btn:hover {
  background: #3478ab;
  cursor:pointer;
}

.pagination__button {
  background: #002c4d;
  color: #fff;

  &:hover {
    color: #fff;
    background: #00487d;
  }
}

#team__member__img {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#team__member__img:hover {opacity: 0.7;}

.modal {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}


.modal-content {
  margin: auto;
  display: block;
  width: 40%;
  max-width: 700px;
}

.modal-content {
  animation-name: modalZoom;
  animation-duration: 0.6s;
}

@keyframes modalZoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }

  .otherEmployeeSections ul {
    column-count: 1 !important;
  }

  .slider__title {
    font-size: 16px;
  }

  .slider__slogan {
    padding-top: 20px;
    font-size: 12px;
    line-height: 1em;
  }
}

.accordion__button {
  background-color: #00223d;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: 1px solid #fff;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion__button:hover {
  background-color: #21537a;
}

.accordion__container__active  {
  background-color: #21537a;
  margin-bottom:20px;
}

.accordion__container {
  padding: 0px 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}