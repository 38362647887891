.slider {
  position: relative;
  overflow: hidden;
}

.slider__img {
  height: 450px;
  max-width: none;
}

.slider__img.is-selected {
  animation-name: zoom;
  animation-duration: 8s;
}

.slider__badge {
  position: absolute;
  z-index: 9;
  padding: 0 55px 0 180px;
  bottom: 80px;
  left: 0;
  color: #fff;
  background: rgba($color--main, 0.8);
  text-transform: uppercase;
  line-height: 2.3em;
}

.slider__win {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
}

.slider__title {
  padding-top: 40px;
  font-size: 27px;
  margin: 0;
}

.slider__slogan {
  padding-bottom: 30px;
  font-size: 18px;
  text-transform:none;
}

.slider-main {
  height: 450px;
}

.slider-mobile {
  height: 130px;
  overflow: hidden;
}

@include media-breakpoint-down(md) {
  .slider__badge {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .slider-mobile .slider__img {
    min-height: 130px;
    min-width: 100%;
  }

  .slider__win {
    display: none;
  }
}

@keyframes zoom {
  0%   {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}