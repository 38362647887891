.breadcrumbs {
  @extend .row;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 45px;
  list-style: none;
}

.breadcrumbs__item {
  @extend .col-auto;
  position: relative;
  font-size: 13px;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 5px;
    height: 8px;
    width: 8px;
    border-color: #002c4d;
    border-style: solid;
    border-width: 0 1px 1px 0;
    transform: rotate(-45deg);
    margin-left: 3px;
  }

  &:last-child {
    &::before {
      display: none;
    }

    font-weight: bold;
  }

  a {
    color: $color--main;
  }
}