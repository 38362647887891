.team {
  margin-bottom: 30px;
}

.team__list {
  @extend .row;
  margin-left: -30px;
}

.team__member {
  @extend .col-auto;
  padding: 0 30px;
  width: 320px;
  margin-bottom: 50px;
}

.team__member__photo {

}

.team__member__name {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 8px;

  a {
    color: #000;
  }
}

.team__member__position {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
}

.team__member__work {
  position: relative;
  margin: 0;
  padding: 17px 0 0;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 4px;
    background: $color--main;
  }
}

.team__member__work__single {
  font-size: 14px;
}

.team__mebmer__photo {
  display: block;
  width: 200px;
  height: 290px;
  position: relative;
  border: 1px solid $color--main;
  overflow: hidden;
  margin-bottom: 25px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-shadow: inset 0 0 0 5px #fff;
  }

  a {
    display: block;
  }
}

.team__mebmer__photo__img {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}

.team-full {}

.team-full .team__mebmer__photo {
  margin-right: 60px;
}

.team-full__title {
  margin: 0 0 10px;
  font-size: 30px;
}

.team-full__subtitle {
  font-size: 16px;
  margin-bottom: 20px;
}

.team-full .team__member__work {
  font-size: 16px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.team-full .team__member__work__single {
  margin-bottom: 5px;
}

.team-full h2 {
  margin-top: 50px;
}