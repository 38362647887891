.awards-list {
  margin-top: 35px;
}

.awards-list__single {
  width: 375px;
  margin-right: 60px;
  margin-bottom: 60px;
  border: 1px solid #c7d1d8;
  padding: 40px 20px 20px 40px;
  font-size: 14px;

  &:last-child {
    margin-right: 0;
  }
}

.awards-list__single__logo {
  position: relative;
  min-height: 100px;
  padding-bottom: 30px;
}

.awards-list__single__title {
  font-size: 16px;
  font-weight: bold;
}