.navigation {
  display: flex;
  position: relative;
  font-weight: bold;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation__single {

}

.navigation__single__link--active {
  box-shadow: inset 0px -10px 0px 0px $color--main;
}

.navigation__single:hover .navigation__submenu {
  display: block;
}

.navigation__single:hover .navigation__single__link {
  box-shadow: inset 0px -10px 0px 0px $color--main;
}

.navigation__single__link {
  display: block;
  padding: 0 14px 20px;
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #000;
    box-shadow: inset 0px -10px 0px 0px $color--main;
  }
}

.navigation__submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  list-style: none;
  border: 1px solid #d9e0e4;
  margin: 0;
  padding: 10px 0;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.22);
  z-index: 99;
}

.navigation__submenu__career {
  left: 740px;
}


.navigation__submenu__single {
}

.navigation__submenu__single__link {
  display: block;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 25px;
  margin: 20px 0;
}

.navigation__submenu__double__link {
  display: block;
  color: #000;
  font-size: 14px;
  padding: 10px 25px;
  text-decoration: none;

  &:hover {
    background: #f5f5f5;
    text-decoration: none;
  }
}

.nav {

}

.nav__button {
  display: none;
  background: #fff;
  color: $color--main;
  font-size: 15px;
  font-weight: bold;
  position: relative;
  border: 1px solid #d9e0e4;
  margin-bottom: 20px;
  height: 35px;
  padding: 0 15px 0 50px;
  cursor: pointer;
}

.nav__button__hamburger {
  position: absolute;
  height: 2px;
  width: 25px;
  left: 10px;
  top: 16px;
  background: $color--main;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background: $color--main;
  }

  &::before {
    bottom: 100%;
    margin-bottom: 3px;
  }

  &::after {
    top: 100%;
    margin-top: 3px;
  }
}

.navigation__close,
.navigation__single .arrow {
  display: none;
}

@media screen and (max-width: 1300px) {
  .nav__button {
    display: block;
  }

  .navigation {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color--main;
    z-index: 999;
    overflow: scroll;
    padding-bottom: 60px;
    padding-top: 10px;
  }

  .navigation__single {
    margin: 25px 0;

    &:hover {
      .arrow {
        transform: rotate(45deg);
        vertical-align: top;
        margin-top: 5px;
      }
    }
  }

  .navigation__single .arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
  }

  .navigation__single__link {
    color: #fff;
    display: inline-block;
    padding: 0;
    margin: 0 25px;

    &:hover {
      color: #ccc;
    }
  }

  .navigation__submenu__double__link {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    margin: 10px 0;
  }

  .navigation__submenu {
    position: relative;
    background: none;
    border: 0;
    box-shadow: none;
    padding-left: 20px;
    margin-bottom: 40px;
    margin: 10px 0 0;
  }

  .navigation__submenu__single__link {
    color: #fff;
    font-weight: normal;
    display: inline-block;
    margin: 10px 0;

    &:hover {
      color: #ccc;
    }
  }

  .navigation__close {
    display: block;
    position: fixed;
    right: 10px;
    top: 10px;
  }

  .navigation__close__button {
    border: 0;
    background: none;
    width: 32px;
    height: 32px;

    &::before,
    &::after {
      position: fixed;
      right: 25px;
      top: 10px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #fff;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}