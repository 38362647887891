.coronavirus {
  text-align: center;
  margin-bottom: 2em;
  padding: 1em;
  background-color: rgb(255,169,117);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.coronavirus a {
  text-decoration: underline;
}