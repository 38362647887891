.right-area {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
  background: $color--main url(../img/right-areas-bg.png) no-repeat 0 50%;
  padding: 30px 30px 25px;
  height: 110px;
  text-decoration: none;
}

a.right-area {
  justify-content: space-between;
}

section.right-area {
  justify-content: center;
}

.right-area__title {
  text-align:center;
}

.right-area:hover {
  color: white;
  text-decoration: underline;
}

.right-area-parent a {
  text-decoration: none;
}

.right-area-parent a:hover {
  color: white;
  text-decoration: underline;
}

.right-area__content {
  border: 1px solid #c7d1d8;
  border-top: 0;
  padding: 30px 30px 25px;
  font-size: 13px;
  line-height: 22px;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(233,233,233,1) 40%, rgba(233,233,233,1) 100%);
}