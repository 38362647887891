.padding-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

.main-group {
  padding-top: 65px;
  padding-bottom: 60px;
}

.main-group__logo {
  margin-bottom: 45px;
}

.main-group__text {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  margin-bottom: 45px;
  line-height: 26px;
}

.main-group__banner {
  border: 1px solid #bfcad2;
  height: 150px;
  width: 310px;
  margin: 0 10px 40px;
}

.main-group__banner__image {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block-blue {
  background: $color--main url(../img/blue-bg.jpg) no-repeat 50% 50%;
  background-size: cover;
  color: #fff;
  padding: 80px 0 40px;
}

.block-blue__wrapper {
  margin-bottom: 30px;
}

.block-blue__title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 35px;
}

.block-blue__banner {
  padding: 10px;
  background: #073252;
  border: 1px solid #899dad;
  display: inline-block;
}

.block-blue__more {
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
}

.arrow {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: #002c4d;
  border-width: 0px 1px 1px 0px;
  transform: rotate(-45deg);
  margin-left: 3px;

  &--white {
    border-color: #fff;
  }
}

.block-news {
  padding-top: 75px;
}

.news-single {
  margin-bottom: 75px;
}

.news-single__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.news-single__date {
  color: #a3a3a3;
  font-size: 14px;
  margin-bottom: 20px;
}

.news-single__content {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 30px;
}

.news-single__archive a {
  font-size: 14px;
  color: #002c4d;
}

.hr-line {
  margin: 20px 0;
  color: $color--main;
  background: $color--main;
}

h1 {
  margin-top: 40px;
  margin-bottom: 45px;
  font-weight: bold;
  font-size: 44px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #bfcad2;
  padding-bottom: 15px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.contacts {
  padding-right: 85px;

  @include media-breakpoint-down(md) {
    padding-right: 0;
  }
}

.contacts__first {
  padding-bottom: 60px;
}

.contacts__map {
  height: 100%;
  width: 100%;
}

.list {
  padding-left: 25px;
}

.list__single {
  position: relative;
  margin: 10px 0;
  list-style: none;
  padding-left: 25px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background: $color--main;
  }
}

.max-width-730 {
  max-width: 850px;
  text-align: left;
  flex-basis: 480px;
}

.content {
  font-size: 16px;
  line-height: 26px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.hr {
  width: 90px;
  background: $color--main;
  color: $color--main;
  height: 4px;
  margin: 20px 0;
}

.single-page h2 {
  margin-top: 60px;
  margin-bottom: 25px;
}

.single-page__side {
  width: 320px;
}

.single-page__side .right-area__title {
  position: relative;
  min-height: 100px;
  display: flex;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  &:hover {
    color: #fff;
  }
}

.single-page__side .arrow {
  height: 20px;
  width: 20px;
  border-width: 0 2px 2px 0;
}

p a {
  color: $color--main;
  text-decoration: underline;
}

p a:hover {
  text-decoration: none;
}

a {
  color:black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color:black;
}

.art-image {
  position: relative;

  img {
    width: 100%;
  }
}

.art-image__text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 88px;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.art-content {
  margin-left: auto;
  margin-right: auto;
  
  li:first-child {
    padding-left: 0px;
  }
}